/* ==========================================================================
   Nav
   ========================================================================== */

.c-nav {
	display: flex;
	padding: 1px 0;
	border-right: 1px solid #414159;
	margin: 0 10px 0 auto;
	margin-top: 38px;
}

.c-nav .c-nav__link {
	margin: 0 10px;
	font-size: 13px;
	font-weight: 400;
	line-height: 1;
	color: #fff;
	letter-spacing: 0.1em;
}

.c-nav .c-nav__link:hover {
	color: #007eff;
	text-decoration: none;
}

@media (max-width: 1024px) {
	.c-nav {
		margin-right: 10px;
	}

	.c-nav .c-nav__link {
		margin: 0 10px;
		font-size: 11px;
	}
}

@media (max-width: 767px) {
	.c-nav {
		display: none;
	}
}
