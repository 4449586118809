/* ==========================================================================
   Article
   ========================================================================== */

.c-article .c-article__leadline {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	text-transform: uppercase;;
	letter-spacing: 0.2em;
}

.c-article .c-article__headline {
	margin: 15px 0;
	font-size: 30px;
	line-height: 1.33;
	color: #007eff;
	text-transform: uppercase;;
	letter-spacing: 0.2em;
}

.c-article .c-article__copy {
	margin: 0;
}

.c-article .c-article__list {
	list-style: none;
	padding: 16px 0 0 20px;
	margin: 0;
}

.c-article .c-article__item {
	position: relative;
	padding: 0 0 5px 20px;
}

.c-article .c-article__item:before {
	position: absolute;
	top: 10px;
	left: 0;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #007eff;
	content: '';
}

@media (max-width: 1024px) {
	.c-article .c-article__headline {
		font-size: 24px;
	}
}

@media (max-width: 767px) {
	.c-article .c-article__leadline {
		line-height: 1.2;
	}

	.c-article .c-article__headline {
		font-size: 20px;
		line-height: 1.2;
	}
}
