/* ==========================================================================
   Copyright
   ========================================================================== */

.c-copyright {
	padding: 100px 0;
	margin: 0;
	font-size: 12px;
	line-height: 1;
	color: #9393a9;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0.2em;
}

@media (max-width: 1024px) {
	.c-copyright {
		padding: 50px 0;
	}
}

@media (max-width: 767px) {
	.c-copyright {
		font-size: 10px;
		padding: 20px 0;
	}
}
