/* ==========================================================================
   Section
   ========================================================================== */

.c-section {
	max-width: 980px;
	margin-left: auto;
	margin-right: auto;
}

.c-section .c-section__headline {
	margin: 0 0 70px;
	font-size: 20px;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0.2em;
	text-align: center;
	text-transform: uppercase;
}

.c-section .c-section__headline:after {
	display: block;
	width: 60px;
	height: 2px;
	margin: 40px auto 0;
	background-color: #007eff;
	content: '';
}

.c-section--features {
	position: relative;
	z-index: 2;
	min-height: 610px;
	padding: 70px 0;
	border-radius: 10px;
	margin-top: -350px;
	background-color: #fff;
	box-shadow: 0 10px 50px rgba(29, 114, 200, 0.1);
}

.c-section--features .c-section__body {
	display: grid;
	grid-template-columns: repeat(3, 270px);
	justify-content: center;
}

.c-section--services {
	margin-top: 127px;
	margin-bottom: 189px;
}

.c-section--prices {
	max-width: none;
	padding: 130px 0 280px;
	background-color: #11112f;
}

.c-section--prices .c-section__headline {
	color: #fff;
}

@media (max-width: 1024px) {
	.c-section--features {
		margin-left: 10px;
		margin-right: 10px;
		margin-top: -100px;
	}

	.c-section--features .c-section__body {
		grid-template-columns: repeat(3, 33.33%);
	}

	.c-section--services {
		margin-top: 65px;
		margin-bottom: 90px;
	}

	.c-section--prices {
		padding: 70px 0 140px;
	}
}

@media (max-width: 767px) {
	.c-section .c-section__headline {
		margin-bottom: 35px;
	}

	.c-section .c-section__headline:after {
		margin-top: 20px;
	}

	.c-section--features {
		min-height: 0;
		padding: 35px 0;
		border-radius: 0;
		margin-top: 0;
	}

	.c-section--features .c-section__body {
		display: block;
	}

	.c-section--services {
		margin: 35px 0;
	}

	.c-section--prices {
		padding: 35px 15px 120px;
	}
}
