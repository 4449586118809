/* ==========================================================================
   Feature
   ========================================================================== */

.c-feature {
	position: relative;
	padding: 0 30px;
	text-align: center;
}

.c-feature:before {
	position: absolute;
	top: 30px;
	left: 0;
	bottom: 20px;
	width: 1px;
	background-color: #f4f4f5;
	content: '';
}

.c-feature:first-child:before {
	display: none;
}

.c-feature .c-feature__icon {
	display: block;
	margin: 0 auto 35px;
}

.c-feature .c-feature__headline {
	margin: 0 0 24px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0.1em;
}

.c-feature .c-feature__copy {
	margin: 0;
}

@media (max-width: 767px) {
	.c-feature {
		padding: 15px;
		border: 1px solid #f4f4f5;
		border-radius: 5px;
		margin: 0 15px 15px;
	}

	.c-feature:last-child {
		margin-bottom: 0;
	}

	.c-feature:before {
		display: none;
	}

	.c-feature .c-feature__icon {
		margin-bottom: 20px;
	}

	.c-feature .c-feature__headline {
		margin-bottom: 12px;
	}
}
