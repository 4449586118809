/* ==========================================================================
   Truck
   ========================================================================== */

.c-truck {
	position: relative;
	z-index: 1;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	border-bottom: 1px solid #f6f7f9;
	margin-top: -260px;
}

.c-truck .c-truck__col {
	padding: 260px 0 60px;;
}

.c-truck .c-truck__col:first-child {
	background-color: #f6f7f9;
}

.c-truck .c-truck__image {
	display: block;
	margin: 50px -70px 0 auto;
}

.c-truck .c-truck__content {
	max-width: 410px;
	margin: 87px 0 0 120px;
}

@media (max-width: 1280px) {
	.c-truck .c-truck__image {
		margin-right: -35px;
	}

	.c-truck .c-truck__content {
		max-width: none;
		margin-left: 60px;
		margin-right: 40px;
	}
}

@media (max-width: 1024px) {
	.c-truck .c-truck__col:first-child {
		display: flex;
		align-items: center;
		padding-bottom: 0;
	}

	.c-truck .c-truck__image {
		margin-top: 0;
	}
}

@media (max-width: 767px) {
	.c-truck {
		display: block;
		margin-top: 0;
	}

	.c-truck .c-truck__col {
		padding: 0;
	}

	.c-truck .c-truck__image {
		margin: 30px 0 -40px -30px;
	}

	.c-truck .c-truck__content {
		padding: 40px 30px 30px;
		margin: 0;
	}
}
