/* ==========================================================================
   Phone Number
   ========================================================================== */

.c-phone-number {
	margin: 36px 0 0;
	font-size: 12px;
	line-height: 1;
	font-weight: 500;
	color: #fff;
	letter-spacing: 0.1em;
}

.c-phone-number ~ .c-phone-number {
	margin-left: 10px;
}

.c-phone-number:before {
	position: relative;
	top: 2px;
	display: inline-block;
	width: 14px;
	height: 14px;
	margin-right: 10px;
	background: url(../../images/icon-phone.png) no-repeat 0 0;
	background-size: 100% 100%;
	content: '';
}

.c-phone-number--large {
	padding-top: 200px;
	margin: 0;
	font-size: 30px;
	text-align: center;
}

.c-phone-number--large ~ .c-phone-number--large {
	padding-top: 50px;
}

.c-phone-number--large:before {
	top: 3px;
	margin-right: 15px;
	width: 29px;
	height: 29px;
}

.c-phone-number a {
	color: inherit;
	text-decoration: none;
}

@media (max-width: 1024px) {
	.c-phone-number {
		font-size: 10px;
	}

	.c-phone-number--large {
		font-size: 20px;
		padding-top: 100px;
	}
}

@media (max-width: 980px) {
	.c-phone-number {
		margin-top: 35px;
		font-size: 8px;
	}

	.c-phone-number::before {
		margin-right: 5px;
	}

	.c-phone-number--large {
		padding-top: 70px;
		margin-top: 0;
		font-size: 16px;
	}

	.c-phone-number--large:before {
		top: 10px;
	}
}

@media (max-width: 767px) {
	.c-phone-number ~ .c-phone-number {
		margin-left: 0;
	}
}
