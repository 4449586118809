/* ==========================================================================
   Hero
   ========================================================================== */

.c-hero {
	position: relative;
	z-index: 1;
	height: calc(100vh + 350px);
	background: url(../../images/hero.jpg) no-repeat center center;
	background-size: cover;
	line-height: 1;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
}

.c-hero .c-hero__content {
	position: absolute;
	top: 50vh;
	left: 50%;
	transform: translate(-50%, -50%);
}

.c-hero .c-hero__headline {
	margin: 0;
	font-size: 110px;
	font-weight: 500;
	letter-spacing: 0.11em;
}

.c-hero .c-hero__copy {
	margin: 0 0 15px;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.2em;
}

.c-hero .c-hero__pointer {
	position: absolute;
	top: 100vh;
	left: 50%;
	width: 28px;
	height: 15px;
	margin-top: -70px;
	margin-left: -14px;
	background: url(../../images/hero-arrow.png) no-repeat 0 0;
	background-size: 100% 100%;
}

@media (max-width: 1024px) {
	.c-hero .c-hero__headline {
		font-size: 90px;
	}
}

@media (max-width: 980px) {
	.c-hero {
		height: 100vh;
	}

	.c-hero .c-hero__headline {
		font-size: 60px;
	}

	.c-hero .c-hero__pointer {
		top: auto;
		bottom: 15%;
		margin-top: 0;
	}
}

@media (max-width: 767px) {
	.c-hero {
		height: 100vh;
		background-image: url(../../images/hero@767.jpg);
	}

	.c-hero .c-hero__headline {
		font-size: 36px;
	}
}
