/* ==========================================================================
   Service
   ========================================================================== */

.c-service {
	display: flex;
	align-items: center;
	max-width: 730px;
	padding: 50px 0;
	border: 1px solid #f3f3f4;
	margin: 70px auto 0;
}

.c-service .c-service__image {
	display: block;
	margin-left: -326px;
	box-shadow: 0 10px 50px rgba(29, 114, 200, 0.1);
}

.c-service .c-service__content {
	max-width: 520px;
	margin-left: 50px;
}

.c-service--reversed {
	flex-direction: row-reverse;
}

.c-service--reversed .c-service__image {
	margin-left: 0;
	margin-right: -326px;
}

.c-service--reversed .c-service__content {
	margin-left: 70px;
	margin-right: 50px;
}

@media (max-width: 1480px) {
	.c-service .c-service__image {
		margin-left: -200px;
	}

	.c-service--reversed .c-service__image {
		margin-left: 0;
		margin-right: -200px;
	}
}

@media (max-width: 1280px) {
	.c-service {
		max-width: 800px;
	}

	.c-service .c-service__content {
		max-width: none;
		margin: 0 40px;
	}

	.c-service .c-service__image {
		margin-left: -163px;
	}

	.c-service--reversed .c-service__image {
		margin-left: 0;
		margin-right: -163px;
	}
}

@media (max-width: 1024px) {
	.c-service {
		max-width: 600px;
	}

	.c-service .c-service__image {
		margin-left: -63px;
	}

	.c-service--reversed .c-service__image {
		margin-left: 0;
		margin-right: -63px;
	}
}

@media (max-width: 767px) {
	.c-service {
		display: block;
		padding: 0 0 20px;
		margin: 60px 15px 0;
	}

	.c-service .c-service__image {
		margin: -30px auto 30px;
	}

	.c-service .c-service__content {
		margin: 0 20px;
	}
}
