/* ==========================================================================
   Page
   ========================================================================== */

html,
body { height: 100%; }

body {
	font-family: 'Rubik', sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.73;
	color: #25254c;
	background-color: #11112f;
}
