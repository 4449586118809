/* ==========================================================================
   Footer
   ========================================================================== */

.c-footer {
	position: relative;
	background: linear-gradient(to bottom, #10173a 0%,#11112f 100%);
}

.c-footer:before {
	position: absolute;
	top: -130px;
	left: 50%;
	width: 662px;
	height: 245px;
	transform: translateX(-50%);
	background: url(../../images/truck-small.png) no-repeat 0 0;
	content: '';
}

@media (max-width: 1024px) {
	.c-footer:before {
		top: -100px;
		width: 462px;
		height: 171px;
		background-image: url(../../images/truck-small@1024.png);
	}
}

@media (max-width: 767px) {
	.c-footer:before {
		width: 320px;
		height: 160px;
		background-image: url(../../images/truck-small@767.png);
		background-size: 100% 100%;
	}
}
